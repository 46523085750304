<template>
  <div class="container"></div>
</template>

<script>
export default {
  name: "404"
}
</script>

<style scoped lang="scss">
.container{
  width: 1200px;
  height: 675px;
  margin: 20px auto 20px;
  background-image: url("../assets/image/404.jpg");
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}
</style>